<template>
  <div>
    <div :class="getClassBasedOnRoute">
      <NavBar></NavBar>
      <div id="content-page" class="content-page m-0 " :class="color">
        <router-view />
      </div>
      <Footer />
    </div>
  </div>
</template>
<script>
import NavBar from '../components/socialvue/navbars/NavBar';
import profile from '../assets/images/page-img/avatar.png';
import Footer from '../components/socialvue/footer/Footer';
import { Users } from '@/FackApi/api/chat';

export default {
  name: 'Layout1',
  components: {
    NavBar,
    Footer
  },
  data () {
    return {
      userProfile: profile,
      usersList: Users
    };
  },
  computed: {
    color () {
      console.log(this.$route.meta);
      return this.$route.meta.bgColor;
    },
    getClassBasedOnRoute () {
      const currentPath = this.$route.name;
      const routesWithoutBackground = ['social.proskers-page'];
      const routesWithHighcontrast = ['social.contact-page'];
      console.log(currentPath);
      if (routesWithHighcontrast.includes(currentPath)) {
        return 'title_highlight_color'
      }
      if (routesWithoutBackground.includes(currentPath)) {
        return '';
      }
      return 'background_contrast'
    }
  }
};
</script>
<style>
@import url("../assets/css/custom.css");
@import url("../assets/css/PriceSlider.css");
</style>
